export class AddToCartDM {
    productId; // string
    optionId; // string
    quantity; // number
    productName; // string
    costPrice; // number
    sellPrice; // number
}

export class UpdateCartDM {
    productId; // string
    optionId; // string
    quantity; // number
}

export class RemoveFromCartDM {
    productId; // string
    optionId; // string
}
